import React from "react"
import { makeStyles } from "@material-ui/styles"
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core"
import Markdown from "markdown-to-jsx"

const useStyles = makeStyles({
  text: {
    fontSize: "1.3em",
    color: "darkslategray",
  },
  table: {
    marginBottom: "30px",
  },
})

const TableSlide = ({ data, crosstab, text }) => {
  const classes = useStyles()

  return (
    <div>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {data.header.map(col => {
                return (
                  <TableCell key={col}>
                    <strong>{col}</strong>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows.map((row, index) => {
              return (
                <TableRow key={index}>
                  {row.map(col => {
                    return <TableCell key={col}>{col}</TableCell>
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {text ? (
        <Typography>
          <Markdown>{text}</Markdown>
        </Typography>
      ) : null}
    </div>
  )
}

export default TableSlide
