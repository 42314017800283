import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import TopicsMain from "../components/topics/topicsMain"
import TopicView from "../components/topics/topicView"

export const query = graphql`
  query topic($topicSlug: String) {
    strapiTopic(slug: { eq: $topicSlug }) {
      category {
        categoryHeader
        categorySubheader
        slug
      }
      coverImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slug
      subtitle
      title
      relatedTopics {
        title
        subtitle
        slug
        category
        coverImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      slides {
        slideType
        title
        text
        tool
        data {
          header
          rows
        }
        crosstab
        listStyle
        listItems {
          text
        }
        media {
          publicURL
          extension
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        references {
          text
        }
      }
    }
  }
`

const TopicPage = ({ data: { strapiTopic: topic } }) => {
  
  return (
    <Layout activePage="knowledge">
      <SEO title="Topics" />
      <TopicsMain>
        <TopicView topic={topic} />
      </TopicsMain>
    </Layout>
  )
}

export default TopicPage
