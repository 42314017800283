const gfrCrea = (crea, gender, age, black) => {
  let blackFactor = 1
  if (black) {
    blackFactor = 1.159
  }
  if (gender === "female") {
    if (crea <= 0.7) {
      return (
        144 * Math.pow(crea / 0.7, -0.329) * Math.pow(0.993, age) * blackFactor
      )
    } else {
      return (
        144 * Math.pow(crea / 0.7, -1.209) * Math.pow(0.993, age) * blackFactor
      )
    }
  } else if (gender === "male") {
    if (crea <= 0.9) {
      return (
        141 * Math.pow(crea / 0.9, -0.411) * Math.pow(0.993, age) * blackFactor
      )
    } else {
      return (
        141 * Math.pow(crea / 0.9, -1.209) * Math.pow(0.993, age) * blackFactor
      )
    }
  }
}

const gfrCyst = (cyst, gender, age, black) => {
  let femaleFactor = 1
  if (gender === "female") {
    femaleFactor = 0.932
  }

  if (cyst <= 0.8) {
    return (
      133 * Math.pow(cyst / 0.8, -0.499) * Math.pow(0.996, age) * femaleFactor
    )
  } else {
    return (
      133 * Math.pow(cyst / 0.8, -1.328) * Math.pow(0.996, age) * femaleFactor
    )
  }
}

const gfrCombined = (crea, cyst, gender, age, black) => {
  let blackFactor = 1
  if (black) {
    blackFactor = 1.08
  }
  if (gender === "female") {
    if (crea <= 0.7) {
      if (cyst <= 0.8) {
        return (
          130 *
          Math.pow(crea / 0.7, -0.248) *
          Math.pow(cyst / 0.8, -0.375) *
          Math.pow(0.995, age) *
          blackFactor
        )
      } else {
        return (
          130 *
          Math.pow(crea / 0.7, -0.248) *
          Math.pow(cyst / 0.8, -0.711) *
          Math.pow(0.995, age) *
          blackFactor
        )
      }
    } else {
      if (cyst <= 0.8) {
        return (
          130 *
          Math.pow(crea / 0.7, -0.601) *
          Math.pow(cyst / 0.8, -0.375) *
          Math.pow(0.995, age) *
          blackFactor
        )
      } else {
        return (
          130 *
          Math.pow(crea / 0.7, -0.601) *
          Math.pow(cyst / 0.8, -0.711) *
          Math.pow(0.995, age) *
          blackFactor
        )
      }
    }
  } else if (gender === "male") {
    if (crea <= 0.9) {
      if (cyst <= 0.8) {
        return (
          135 *
          Math.pow(crea / 0.9, -0.207) *
          Math.pow(cyst / 0.8, -0.375) *
          Math.pow(0.995, age) *
          blackFactor
        )
      } else {
        return (
          135 *
          Math.pow(crea / 0.9, -0.207) *
          Math.pow(cyst / 0.8, -0.711) *
          Math.pow(0.995, age) *
          blackFactor
        )
      }
    } else {
      if (cyst <= 0.8) {
        return (
          135 *
          Math.pow(crea / 0.9, -0.601) *
          Math.pow(cyst / 0.8, -0.375) *
          Math.pow(0.995, age) *
          blackFactor
        )
      } else {
        return (
          135 *
          Math.pow(crea / 0.9, -0.601) *
          Math.pow(cyst / 0.8, -0.711) *
          Math.pow(0.995, age) *
          blackFactor
        )
      }
    }
  }
}

export const gfr = (crea, cyst, gender, age, black) => {
  crea = parseFloat(crea)
  cyst = parseFloat(cyst)
  age = parseInt(age)

  if (!isNaN(crea) && isNaN(cyst)) {
    return gfrCrea(crea, gender, age, black)
  } else if (isNaN(crea) && !isNaN(cyst)) {
    return gfrCyst(cyst, gender, age, black)
  } else if (!isNaN(crea) && !isNaN(cyst)) {
    return gfrCombined(crea, cyst, gender, age, black)
  }
}

const gfrReference = (age, gender) => {
  const male = gender === "male"
  if (age < 40) {
    return male ? 100 : 99
  } else if (age < 45) {
    return male ? 96 : 95
  } else if (age < 50) {
    return male ? 93 : 91
  } else if (age < 55) {
    return male ? 90 : 87
  } else if (age < 60) {
    return male ? 86 : 83
  } else if (age < 65) {
    return male ? 83 : 79
  } else if (age < 70) {
    return male ? 80 : 75
  } else if (age < 75) {
    return male ? 77 : 71
  } else if (age < 80) {
    return male ? 73 : 67
  } else {
    return male ? 70 : 63
  }
}

export const kidneyFunctionPct = (gfr, age, gender) => {
  return (gfr / gfrReference(age, gender)) * 100
}

export const ckdStageDesc = gfr => {
  if (gfr >= 90) {
    return {
      stage: "CKD1",
      desc: "Normal Kidney Function.",
      stageSimple: "Normal Function",
    }
  } else if (gfr >= 60) {
    return {
      stage: "CKD2",
      desc: "Mild CKD (Chronic Kidney Disease).",
      stageSimple: "Early Stage Kidney Disease",
    }
  } else if (gfr >= 30) {
    return {
      stage: "CKD3",
      desc: "Moderate CKD (Chronic Kidney Disease).",
      stageSimple: "Early Stage Kidney Disease",
    }
  } else if (gfr >= 15) {
    return {
      stage: "CKD4",
      desc: "Severe CKD (Chronic Kidney Disease).",
      stageSimple: "Late Stage Kidney Disease",
    }
  } else {
    return {
      stage: "CKD5",
      desc: "End Stage CKD (Dialysis required).",
      stageSimple: "Dialysis",
    }
  }
}
