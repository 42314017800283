import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  Breadcrumbs,
  Typography,
  Link,
  Paper,
  Toolbar,
  Divider,
  Fab,
  useScrollTrigger,
  Zoom,
  Grid,
} from "@material-ui/core"
import { navigate } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { ScreenContext } from "../../context/screenContext"
import AgendaSlide from "./slides/agendaSlide"
import ListSlide from "./slides/listSlide"
import MediaSlide from "./slides/mediaSlide"
import References from "./slides/references"
import TableSlide from "./slides/tableSlide"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import ToolSlide from "./slides/toolSlide";
import TopicCard from "./topicCard";

const queryCategories = graphql`
  query topicCategories {
    allStrapiCategory {
      nodes {
        slug
        strapiId
      }
    }
  }
`

const useStyles = makeStyles({
  section: {
    marginBottom: "30px",
  },
  slideContainer: {
    flexGrow: 1,
    padding: "20px 40px",
  },
  slideContainerMobile: {
    flexGrow: 1,
    padding: "10px 20px",
  },
  topbar: {
    backgroundColor: "#FAFAFA",
    padding: "5px 10px",
  },
  navCaption: {
    marginLeft: "5px",
    flexGrow: 1,
  },
  upButton: {
    margin: 0,
    top: "auto",
    right: 60,
    bottom: 60,
    left: "auto",
    position: "fixed",
  },
  upButtonMobile: {
    margin: 0,
    top: "auto",
    right: 40,
    bottom: 80,
    left: "auto",
    position: "fixed",
  },
  subheader: {
    marginTop: "30px",
    marginBottom: "20px"
  }
})

const TopicView = ({ topic }) => {
  const classes = useStyles()
  const { mobile } = useContext(ScreenContext)

  const {
    allStrapiCategory: { nodes: allCategories },
  } = useStaticQuery(queryCategories)

  const getCategory = (id) => {
    const cat = allCategories.filter(c => c.strapiId === id)[0]
    return cat
  }

  const getSlide = slide => {
    if (slide.slideType === "listSlide") {
      return (
        <ListSlide
          listStyle={slide.listStyle}
          contents={slide.listItems.map(item => item.text)}
          mobile={mobile}
        />
      )
    } else if (slide.slideType === "mediaSlide") {
      const svg = slide.media.extension === "svg"
      return (
        <MediaSlide
          src={svg ? slide.media.publicURL : slide.media.childImageSharp.fluid}
          svg={svg}
          content={slide.text}
        />
      )
    } else if (slide.slideType === "tableSlide") {
      return (
        <TableSlide
          title={slide.title}
          data={slide.data}
          crosstab={slide.crosstab}
          text={slide.text}
        />
      )
    } else if (slide.slideType === "toolSlide") {
      return (
        <ToolSlide 
          tool={slide.tool}
        />
      )
    }
  }

  const getReferences = slide => {
    if (!slide) return null
    const references = slide.references
    if (!references || references.length === 0) return null
    return <References data={references.map(ref => ref.text)} />
  }

  const SlideTop = ({ slide }) => {
    return (
      <Toolbar disableGutters variant={"dense"} className={classes.topbar}>
        <Typography
          variant={!mobile ? "h5" : "h6"}
          className={classes.navCaption}
        >
          <strong>{slide.title}</strong>
        </Typography>
      </Toolbar>
    )
  }

  const Slide = ({ slide }) => {
    return (
      <Paper elevation={1} style={{ marginBottom: "15px" }}>
        <SlideTop slide={slide} />
        <Divider />
        <SlideContainer slide={slide} />
      </Paper>
    )
  }

  const SlideContainer = ({ slide }) => {
    return (
      <Paper
        elevation={0}
        className={
          mobile ? classes.slideContainerMobile : classes.slideContainer
        }
      >
        {getSlide(slide)}
      </Paper>
    )
  }

  function ScrollTop({ children }) {
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 100,
    })

    const handleClick = event => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        "#topAnchor"
      )

      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }

    return (
      <Zoom in={trigger}>
        <div onClick={handleClick} role="presentation">
          {children}
        </div>
      </Zoom>
    )
  }

  return (
    <>
      <Breadcrumbs style={{ marginBottom: "20px" }} id="topAnchor">
        <Link color="inherit" href="#" onClick={() => navigate(`/knowledge`)}>
          Knowledge
        </Link>
        <Link
          color="inherit"
          href="#"
          onClick={() => navigate(`/knowledge/${topic.category.slug}`)}
        >
          {topic.category.categoryHeader}
        </Link>
        <Typography color="textPrimary">{topic.title}</Typography>
      </Breadcrumbs>

      <Typography paragraph variant={mobile ? "h5" : "h4"}>
        {topic.title}
      </Typography>

      <div className={classes.section}>
        <AgendaSlide
          image={topic.coverImage}
          contents={topic.slides.map(slide => slide.title)}
          mobile={mobile}
        />
      </div>

      {topic.slides.map((slide, slideNo) => {
        return (
          <div className={classes.section} key={slideNo}>
            <Slide slide={slide} />
            {getReferences(slide)}
          </div>
        )
      })}

      {topic.relatedTopics.length > 0 ?
      <>
      <Typography variant="h5" className={classes.subheader}>
        Related Articles
      </Typography>
      <Grid container spacing={4}>
        {topic.relatedTopics.map(article => {
          return (
            <Grid key={article.slug} item xs={12} sm={4}>
              <TopicCard
                small
                category={getCategory(article.category)}
                topic={article}
              />
            </Grid>
          )
        })}
      </Grid>
      </> : null}

      <ScrollTop>
        <Fab
          color="primary"
          aria-label="scrollUp"
          size="small"
          className={mobile ? classes.upButtonMobile : classes.upButton}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  )
}

export default TopicView
