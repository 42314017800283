import React from "react"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import { Typography } from "@material-ui/core"
import KidneyFunction from "../../tools/tool/labTools/kidneyFunction";

const useStyles = makeStyles({
  
})

const ToolSlide = ({ tool }) => {
  const classes = useStyles()

  if (tool === "kidneyFunction") {
    return <KidneyFunction />
  } 
  else {
    return null
  }
}

export default ToolSlide
