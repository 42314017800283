import React from "react"
import { Typography } from "@material-ui/core"

const References = ({ data }) => {
  return (
    <>
      <Typography variant="body1">
        <strong>References</strong>
      </Typography>
      <ol>
        {data.map((ref, index) => {
          return <li key={index}>{ref}</li>
        })}
      </ol>
    </>
  )
}

export default References
