import React from "react"
import Image from "gatsby-image"
import { Typography } from "@material-ui/core"
import Markdown from "markdown-to-jsx"

const MediaSlide = ({ src, svg, content }) => {
  return (
    <>
      {svg ? (
        <img
          style={{
            width: "100%",
            borderRadius: "3%",
            marginBottom: "25px",
            marginRight: "auto",
            marginLeft: "auto",
            maxWidth: "600px",
          }}
          src={src}
        ></img>
      ) : (
        <Image
          style={{ borderRadius: "3%", marginBottom: "25px", marginLeft: "auto", marginRight: "auto", maxWidth: "600px"}}
          fluid={src}
        />
      )}
      <Typography align="justify" variant="body1">
        <Markdown>{content}</Markdown>
      </Typography>
    </>
  )
}

export default MediaSlide
