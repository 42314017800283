import React, { useContext } from "react"
import NumberFormat from "react-number-format"
import {
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  TextField,
  Select,
  InputAdornment,
  FormLabel,
  RadioGroup,
  Radio,
  Switch,
  Button,
  Typography,
  Collapse,
  Container,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { LabContext } from "../../../../context/labContext"
import { gfr, kidneyFunctionPct, ckdStageDesc } from "./kidneyFunctionCalc"

const useStyles = makeStyles(theme => ({
  formRow: {
    marginBottom: "20px",
    alignItems: "center",
  },
  labelField: {
    width: "140px",
    margin: "0px",
    paddingRight: "20px",
    textAlign: "right",
  },
  valueField: {
    width: "170px",
  },
  ageField: {
    width: "70px",
  },
  inactiveText: {
    color: "gray",
  },
  activeText: {
    color: "black",
  },
  button: {
    marginRight: "10px",
  },
  resultsHeader: {
    padding: "10px 20px",
    backgroundColor: theme.palette.primary.light,
    color: "white",
  },
  resultsDescription: {
    padding: "20px",
    marginBottom: "10px",
  },
}))

const KidneyFunction = () => {
  const { kidneyFunction: context } = useContext(LabContext)

  const classes = useStyles()

  const missingCrea = () => {
    return context.checkCrea && context.crea === ""
  }

  const missingCyst = () => {
    return context.checkCyst && context.cyst === ""
  }

  const missingAge = () => {
    return context.age === ""
  }

  const missingCheck = () => {
    return !context.checkCrea && !context.checkCyst
  }

  const missingFields = () => {
    return missingCrea() || missingCyst() || missingAge() || missingCheck()
  }

  const resetForm = () => {
    context.setCheckCrea(true)
    context.setCrea("")
    context.setCheckCyst(false)
    context.setCyst("")
    context.setAge("")
    context.setGender("female")
    context.setBlack(false)
    context.setEvaluated(false)
  }

  const safeGfr = () => {
    const gfrValue = gfr(
      context.crea,
      context.cyst,
      context.gender,
      context.age,
      context.black
    )
    if (!isNaN(gfrValue)) {
      return gfrValue.toFixed(1)
    } else {
      return "---"
    }
  }

  return (
    <>
        <Typography paragraph>
          Kidney Function can be estimated from <strong>Creatinine</strong> or{" "}
          <strong>Cystatin C</strong> blood tests. Check your lab results and
          provide at least one of both in the form below.
        </Typography>
        <form noValidate autoComplete="off">
          <FormGroup row className={classes.formRow}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={context.evaluated}
                  color="primary"
                  checked={context.checkCrea}
                  onChange={event => {
                    context.setCheckCrea(event.target.checked)
                    if (!event.target.checked) {
                      context.setCrea("")
                    }
                  }}
                  name="creatinineCheck"
                />
              }
              label="Creatinine"
              classes={{
                root: classes.labelField,
                label: !context.checkCrea ? classes.inactiveText : null,
              }}
            />

            <NumberFormat
              customInput={TextField}
              allowNegative={false}
              decimalScale={2}
              disabled={!context.checkCrea || context.evaluated}
              error={missingCrea()}
              size="small"
              className={classes.valueField}
              id="crea"
              value={context.crea}
              onChange={event => context.setCrea(event.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Select
                      value={context.creaUnit}
                      disableUnderline={true}
                      disabled={!context.checkCrea || context.evaluated}
                      onChange={event =>
                        context.setCreaUnit(event.target.value)
                      }
                    >
                      {["mg/dl", "μmol/l"].map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                ),
              }}
            ></NumberFormat>
          </FormGroup>

          <FormGroup row className={classes.formRow}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={context.evaluated}
                  color="primary"
                  checked={context.checkCyst}
                  onChange={event => {
                    context.setCheckCyst(event.target.checked)
                    if (!event.target.checked) {
                      context.setCyst("")
                    }
                  }}
                  name="cystatinCheck"
                />
              }
              label="Cystatin C"
              classes={{
                root: classes.labelField,
                label: !context.checkCyst ? classes.inactiveText : null,
              }}
            />

            <NumberFormat
              customInput={TextField}
              allowNegative={false}
              decimalScale={2}
              disabled={!context.checkCyst || context.evaluated}
              error={missingCyst()}
              size="small"
              className={classes.valueField}
              id="cyst"
              value={context.cyst}
              onChange={event => context.setCyst(event.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" disableTypography>
                    mg/l
                  </InputAdornment>
                ),
              }}
            ></NumberFormat>
          </FormGroup>

          <FormGroup row className={classes.formRow}>
            <FormLabel className={classes.labelField}>Age</FormLabel>
            <NumberFormat
              disabled={context.evaluated}
              allowLeadingZeros={false}
              format="###"
              error={missingAge()}
              customInput={TextField}
              size="small"
              className={classes.ageField}
              id="age"
              value={context.age}
              onChange={event => context.setAge(event.target.value)}
              variant="outlined"
            ></NumberFormat>
          </FormGroup>

          <FormGroup row className={classes.formRow}>
            <FormLabel className={classes.labelField}>Gender</FormLabel>
            <RadioGroup
              style={{ flexDirection: "row" }}
              aria-label="gender"
              name="gender1"
              value={context.gender}
              onChange={event => context.setGender(event.target.value)}
            >
              <FormControlLabel
                value="female"
                control={<Radio color="primary" disabled={context.evaluated} />}
                label="Female"
              />
              <FormControlLabel
                value="male"
                control={<Radio color="primary" disabled={context.evaluated} />}
                label="Male"
              />
            </RadioGroup>
          </FormGroup>

          <FormGroup row className={classes.formRow}>
            <FormLabel className={classes.labelField}>Black Skin</FormLabel>
            <Switch
              disabled={context.evaluated}
              color="primary"
              checked={context.black}
              onChange={event => context.setBlack(event.target.checked)}
              name="black"
              edge="start"
            />
          </FormGroup>

          <FormGroup row className={classes.formRow}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disabled={missingFields() || context.evaluated}
              onClick={() => {
                context.setEvaluated(true)
              }}
            >
              Evaluate
            </Button>
            <Button onClick={resetForm}>Reset</Button>
          </FormGroup>
        </form>

      <Collapse in={context.evaluated} timeout="auto">
        <Paper>
          <Paper className={classes.resultsHeader}>
            <Typography variant="h6">
              Estimated Kidney Function:{" "}
              <strong>
                {Math.round(
                  kidneyFunctionPct(safeGfr(), context.age, context.gender)
                )}
                %
              </strong>
            </Typography>
            <Typography variant="overline">
              {ckdStageDesc(safeGfr()).stageSimple}
            </Typography>
          </Paper>
          <Paper className={classes.resultsDescription}>
            <Typography>
              <strong>Estimated GFR</strong> (Glomerular Filtration Rate):{" "}
              <strong>{safeGfr()}</strong> ml/min/1,73 m²
            </Typography>
            <Typography>
              Official CKD Classification:{" "}
              <strong>{ckdStageDesc(safeGfr()).stage}</strong>:{" "}
              {ckdStageDesc(safeGfr()).desc}
            </Typography>
          </Paper>
        </Paper>
      </Collapse>

      <Container className={classes.resultsDescription}>
        <Typography variant="caption">
          Estimation based on Formulas developed by CKD-EPI (Chronic Kidney
          Disease Epidemiology Collaboration). For details see: Inker, Schmid,
          Tighiouart, et al., Estimating glomerular filtration rate from serum
          creatinine and cystatin, N Engl J Med 2012; 367:20-29
        </Typography>
      </Container>
    </>
  )
}

export default KidneyFunction
