import React from "react"
import { Grid, Typography } from "@material-ui/core"
import Image from "gatsby-image"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"

const useStyles = makeStyles({
  list: {
    listStyle: "square outside none",
    padding: "5px 15px",
  },
  listItem: {
    padding: "5px 0px",
  },
  text: {
    fontSize: "1.2em",
  },
})

const AgendaSlide = ({ image, contents, mobile }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={6}>
      <Grid item xs={4} sm={5}>
        <Image
          style={{ borderRadius: "3%", marginTop: "5px" }}
          fluid={image.childImageSharp.fluid}
        />
      </Grid>
      <Grid item xs={8} sm={7}>
        <Typography variant={mobile ? "body1" : "h6"} color="textSecondary">
          Contents
        </Typography>
        <Typography variant="body1" component="ul" className={classes.list}>
          {contents.map((item, index) => {
            return (
              <li
                key={index}
                className={clsx(
                  classes.listItem,
                  !mobile ? classes.text : null
                )}
              >
                {item}
              </li>
            )
          })}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AgendaSlide
