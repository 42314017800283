import React from "react"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import { Typography } from "@material-ui/core"
import Markdown from "markdown-to-jsx"

const useStyles = makeStyles({
  bulletList: {
    listStyle: "square outside none",
    padding: "5px 15px",
  },
  orderedList: {
    padding: "5px 25px",
  },
  listItem: {
    padding: "10px 0px",
  },
  text: {
    fontSize: "1.2em",
    color: "darkslategray",
  },
})

const ListSlide = ({ listStyle, contents, mobile }) => {
  const classes = useStyles()

  return (
    <Typography
      variant="body1"
      component={listStyle === "ordered" ? "ol" : "ul"}
      className={
        listStyle === "ordered" ? classes.orderedList : classes.bulletList
      }
    >
      {contents.map((item, index) => {
        return (
          <li
            key={index}
            className={clsx(classes.listItem, !mobile ? classes.text : null)}
          >
            <Markdown>{item}</Markdown>
          </li>
        )
      })}
    </Typography>
  )
}

export default ListSlide
